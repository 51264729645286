/*CCEE Import Styles*/

.cceeImportContainer {
    border-radius: 5px;
    border: 1px Solid #DCDEEA;
    max-width: 500px;
}

.cceeImportInput {
    display: none;
}

.cceeImportSpan {
    background-color: #EAECF4;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin:0;
    z-index: 1;
    padding: 10px;
    border-left: 1px solid #DCDEEA;
    color: #908D95;
}
.infoContainer:hover {
    opacity: 0.5;
}

