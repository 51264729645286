/*Admin List Styles*/

.adminListFooterButton {
    border-radius: 0 !important; 
    width: 90px;
}

.adminListFooterButtonMiddle {
    border-right: 1px Solid gray !important;
    border-left: 1px Solid gray !important;
}

.adminListActionCell {
    border-radius: 100%;
    background-color: blue;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}