/*Custom Table Styles*/

.customTableActionCell {
    border-radius: 100%;
    border: none;
    overflow: visible;  
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.customTableEditCell {
    background-color: blue;
}

.customTableBlockChainCell {
    background-color: green;
}

.customTableCheckCell {
    background-color: green;
}

.customTableCloseCell {
    background-color: red;
}

.customTableDownloadCell {
    background-color: lightseagreen;
}

.customTableUploadCell {
    background-color: orange;
}

.adminRegisterInput {
    height: 40px; 
    border-radius: 5px; 
    overflow: visible;  
    border: none; 
    border: 1px Solid black;
}