/*Custom Enterprise Details Styles*/

.enterpriseDetailTableTitle {
    color: blue;
    margin: 0;
    font-size: 23px;
}

.enterpriseDetailModalTitle {
    color: black;
    margin: 0;
    font-size: 23px;
}