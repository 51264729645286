/*Theme Blue Styles*/
$app-primary-blue: #2196F3 !default;
$secondary-blue: #FF6E40;

$sidebar-blue: rgba($app-primary-blue, 0.25) !default;
$sidebar-text-blue: #868e96 !default;

//-Base-scss
.blue .right-arrow {
  color: $app-primary-blue;
  &:after {
    color: $app-primary-blue;
  }
}

//- Bootstrap file Style
.blue {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: darken($app-primary-blue, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-blue !important;
  }

  & .page-link {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: $app-primary-blue;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-blue !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $app-primary-blue;
    border-color: $app-primary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-blue, 5%) !important;
      border-color: darken($app-primary-blue, 5%) !important;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #BBDEFB !important;
    }

    &.lighten-3 {
      background-color: #90CAF9 !important;
    }

    &.lighten-2 {
      background-color: #64B5F6 !important;
    }

    &.lighten-1 {
      background-color: #42A5F5 !important;
    }

    &.darken-1 {
      background-color: #1E88E5 !important;
    }

    &.darken-2 {
      background-color: #1976D2 !important;
    }

    &.darken-3 {
      background-color: #1565C0 !important;
    }

    &.darken-4 {
      background-color: #0D47A1 !important;
    }

    &.accent-1 {
      background-color: #82B1FF !important;
    }

    &.accent-2 {
      background-color: #448AFF !important;
    }

    &.accent-3 {
      background-color: #2979FF !important;
    }

    &.accent-4 {
      background-color: #2962FF !important;
    }
  }
}

//Secondary
.blue {
  .jr-link.text-secondary {
    color: $secondary-blue;
    &:focus,
    &:hover {
      color: darken($secondary-blue, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-blue !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-blue !important;
    color: $white !important;
  }

  & .btn-secondary {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-blue, 5%) !important;
      border-color: darken($secondary-blue, 5%) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.blue .app-main-header {
  background-color: $app-primary-blue !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.blue .color-theme-header {
  background-color: $app-primary-blue;
}

//_sidebar.scss
.blue .side-nav {
  background-color: $white !important;
  color: $sidebar-text-blue !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));

  & .user-profile {
    background-color: $white;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: $jr-border;
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-blue, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-blue;
    }
  }
}

.blue .nav-section {

  & .nav-header {
    color: $sidebar-text-blue;
    border-color: $gray-400;
  }
}

.blue .nav-collapse {

  & .nav-collapse-btn {
    color: $sidebar-text-blue;

    &:focus,
    &:hover {
      background-color: $white;
      color: $app-primary-blue;
    }
  }

  &.open {
    background-color: $white;

    & .nav-collapse-btn {
      background-color: $white;
      color: $app-primary-blue;

      &:focus,
      &:hover {
        background-color: $white;
        color: $app-primary-blue;
      }
    }
  }
}

.blue .nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-blue;

    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $app-primary-blue;
    }

    &:after {
      background-color: $app-primary-blue;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      background-color: $sidebar-blue;
      color: $app-primary-blue;
    }
  }
}

.blue .nav-menu-item {
  & .nav-menu-link {

    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $app-primary-blue;
    }
  }
}

/*Header top Navbar Styles*/
.blue .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-blue;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-blue;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-blue;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-blue;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-blue;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-blue;
    }
  }
}

.blue .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-blue;
    }
  }
}

.blue .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.blue .header-notifications .app-notification {
  & .jr-list-link {

    @include hover-focus-active {
      color: $app-primary-blue;
      border-color: $app-primary-blue;
    }
  }
}

//_app-module.scss
.blue .module-nav {
  & li {
    & a.active {
      border-color: $app-primary-blue;
    }

    & .jr-link.active {
      color: $app-primary-blue;
    }
  }
}

//_calendar.scss
.blue .rbc-event {
  background-color: $app-primary-blue;
}

.blue .rbc-event.rbc-selected {
  background-color: darken($app-primary-blue, 10%);
}

.blue .rbc-slot-selection {
  background-color: rgba($app-primary-blue, 0.7);
}

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:hover {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

//_chat.scss
.blue .chat-sidenav-title {
  color: $app-primary-blue;
}

.blue .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-blue, 45%);
  }
}

//_dashboard.scss
.blue .contact-list {
  & i {
    color: $app-primary-blue;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.blue .Collapsible__trigger {
  background: $app-primary-blue;
}

.blue .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-blue;
  }
}

//_login.scss
.blue .login-content .form-control {
  &:focus {
    border-color: $app-primary-blue;
  }
}

//_portfolio.scss
.blue .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-blue;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-blue;
    }
  }
}

//_card.scss
.blue .profile-intro {
  & .icon {
    color: $app-primary-blue;
  }
}

.blue .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-blue;
  }
}

//_tables.scss
.blue .actions {
  color: $secondary-blue;
}

.blue .table-hover tbody tr:hover {
  background-color: rgba($app-primary-blue, 0.075);
}

//Border Color

.blue .border-primary {
  border-color: $app-primary-blue !important;
}

// login page content

.blue .app-logo-content {
  background-color: $app-primary-blue;
}

.blue .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-blue;
    color: $app-primary-blue;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-blue;
    }
  }
}

/*Button Group Styles*/
.blue .btn-group,
.blue .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-blue;
      border-color: $app-primary-blue;
    }

  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-blue;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-blue, 0.12);
        color: $app-primary-blue;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.blue .jr-fillchart-btn-close,
.blue .jr-onchart .jr-badge-up,
.blue .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-blue;
}

.blue .jr-entry-title:before {
  background-color: $app-primary-blue;
}

.blue .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-blue;
  }
}

.blue .slick-dots li.slick-active button:before {
  border-color: $app-primary-blue;
}

//Nav Styles
.blue .nav-pills .nav-link.active,
.blue .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-blue;
}

// Gradient Color Class
.blue .bg-gradient-primary {
  @include gradient-directional($app-primary-blue, lighten($app-primary-blue, 16%), 0deg);
}

.blue .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-blue, 10%), lighten($app-primary-blue, 16%), 70%, 100%);
}

//Profile style
.blue .jr-profile-banner {
  background-color: $app-primary-blue;
  color: $white;

  & .jr-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-blue;
    }
  }
}

.blue .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-blue;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}